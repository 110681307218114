import React, { useState } from "react";
import "./Header.css";
import logo from "../assets/ssfd-logo.png";
import { useNavigate, Link } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="header">
      <div className="header-container">
        <div className="logo-container">
          <img
            src={logo}
            alt="SSFD Logo"
            className="logo"
            onClick={() => navigate("/")}
          />
        </div>

        <button
          className="menu-toggle"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-expanded={isMenuOpen}
        >
          <span className="hamburger"></span>
        </button>

        <nav className={`nav-menu ${isMenuOpen ? "active" : ""}`}>
          <ul>
            <li>
              <Link to="/home">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li className="dropdown">
              <span>Services</span>
              <ul className="dropdown-menu">
                <li>
                  <Link to="/resources">Resources</Link>
                </li>
                <li>
                  <Link to="/prevention">Prevention</Link>
                </li>
                <li>
                  <Link to="/education">Education</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/permits">Permits</Link>
            </li>
            <li>
              <Link to="/news">News</Link>
            </li>
            <li>
              <Link to="/public-records">Public Records</Link>
            </li>
            <li>
              <Link to="/recruitment-and-hiring">Careers</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
