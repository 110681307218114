import React from "react";
import "./Home.css";
import SSFD from "../assets/ssfd01.jpg";

function Home() {
  return (
    <div className="home-container">
      <div className="hero-section">
        <img
          src={SSFD}
          alt="South Summit Fire Protection District"
          className="hero-image"
        />
        <div className="hero-overlay">
          <h1>South Summit Fire Protection District</h1>
          <p>Serving Our Community with Pride and Dedication</p>
        </div>
      </div>
    </div>
  );
}

export default Home;
